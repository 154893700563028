body {
  background: #222;
  color: #16C60C;
  font-family: monospace;
}

head {
  display: flex;
  align-items: baseline;
}

.dark-form-input {
  display: flex;
  flex-direction: row;
  color: #16C60C;
  margin:12px
}

.input {
  padding-left: 10px;
  border: none;
  border-color: #0C0C0C;
  background-color: #0C0C0C;
  color: #16C60C;
  font-family: monospace;
  font-size: calc(10px + 2vmin);
  text-align: left;
  overflow: hidden;
  resize:none;
}

.input:focus {
  outline: none !important;
}